import Vue from 'vue';
import App from './App.vue';
import store from './store';
import ElementUI from 'element-ui';
import router from './router';
import axios from 'axios';

import 'element-ui/lib/theme-chalk/index.css';
import videoPlayer from 'vue-video-player';
// 清除css默认样式
import '@/styles/reset.css';
// 公共样式
import '@/styles/helpers.scss';
// 字体图标
import '@/styles/iconfont.css';

// Vue.use(VideoPlayer);

const hls = require('videojs-contrib-hls');

Vue.use(ElementUI);
Vue.use(hls);
Vue.use(videoPlayer);

const baseUrl = {
    install: (Vue: any, options: any) => {
        // BASEURL全局API 用于请求验证码
        Vue.prototype.BASEURL = 'https://crjyxspt.zikaoj.com/api/v1';
        // Vue.prototype.BASEURL = 'https://ceshi.zikaoj.com:9088/api/v1';
        // Vue.prototype.BASEURL = 'http://192.168.0.7:8089/api/v1';
    }
};

Vue.use(baseUrl);

Vue.config.productionTip = false;

axios.interceptors.request.use(
    (config: any) => {
        if (localStorage.myToken) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = `${localStorage.myToken}`;
        }
        return config;
    },
    (err: any) => {
        return Promise.reject(err);
    });

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
