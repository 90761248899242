import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'login',
        component: () => import('../pages/login/login.vue')
    },
    {
        path: '/courseWork',
        name: 'courseWork',
        component: () => import('../pages/courseWork/courseWork.vue')
    },
    {
        path: '/courseVideo',
        name: 'courseVideo',
        component: () => import('../pages/courseWork/myCourse/courseVideo.vue')
    },
    {
        path: '/liveCourse',
        name: 'liveCourse',
        component: () => import('../pages/liveCourse/liveCourse.vue')
    },
    {
        path: '/liveVideo',
        name: 'liveVideo',
        component: () => import('../pages/liveCourse/liveVideo.vue')
    },
    {
        path: '/myHomework',
        name: 'myHomework',
        component: () => import('../pages/courseWork/myHomework/myHomework.vue')
    },
    {
        path: '/answerSheet',
        name: 'answerSheet',
        component: () => import('../pages/courseWork/myHomework/answerSheet.vue')
    },
    {
        path: '/answerReport',
        name: 'answerReport',
        component: () => import('../pages/courseWork/myHomework/answerReport.vue')
    },
    {
        path: '/answerAnalysis',
        name: 'answerAnalysis',
        component: () => import('../pages/courseWork/myHomework/answerAnalysis.vue')
    },
    {
        path: '/myExam',
        name: 'myExam',
        component: () => import('../pages/myExam/myExam.vue')
    }, {
        path: '/examinationAnswer',
        name: 'examinationAnswer',
        component: () => import('../pages/myExam/examinationAnswer.vue')
    },
    {
        path: '/myGrades',
        name: 'myGrades',
        component: () => import('../pages/myGrades/myGrades.vue')
    },
    {
        path: '/myThesis',
        name: 'myThesis',
        component: () => import('../pages/myThesis/myThesis.vue')
    },
    {
        path: '/myStudentStatus',
        name: 'myStudentStatus',
        component: () => import('../pages/myStudentStatus/myStudentStatus.vue')
    },
    {
        path: '/myNews',
        name: 'myNews',
        component: () => import('../pages/myNews/myNews.vue')
    },
    {
        path: '/dataReview',
        name: 'dataReview',
        component: () => import('../pages/dataReview/dataReview.vue')
    },
    {
        path: '/questionnaire',
        name: 'questionnaire',
        component: () => import('../pages/questionnaire/questionnaire.vue')
    },
    {
        path: '/quesAnSheet',
        name: 'quesAnSheet',
        component: () => import('../pages/questionnaire/answerSheet.vue')
    },
    {
        path: '/505',
        name: '505',
        component: () => import('../pages/505.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../pages/404.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
